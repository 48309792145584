import { Box, Typography, Checkbox } from '@mui/material';
import classes from './styles.module.scss';
import { IShipmentUser } from '@app/types';
import { HedgingStatusIndicatorMobile } from '@common/components';
import { renderDateOrNone } from '@app/utils';

interface ShipmentCardProps {
  shipment: IShipmentUser;
  ordinalNumber: number;
  selectable?: boolean;
  selected?: boolean;
  onSelect?: () => void;
  onHedgeClick?: () => void;
}

export const ShipmentCard = ({
  shipment,
  ordinalNumber,
  selectable,
  selected,
  onSelect,
  onHedgeClick,
}: ShipmentCardProps) => {
  return (
    <Box
      className={`${classes.shipmentItem} ${selectable ? classes.selectable : ''}`}
      onClick={onSelect || (onHedgeClick && shipment.company_hedge ? onHedgeClick : undefined)}
    >
      <Box className={classes.shipmentHeader}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {selectable && (
            <Checkbox
              checked={selected}
              onClick={(e) => e.stopPropagation()}
              onChange={onSelect}
              sx={{ padding: 0 }}
            />
          )}
          <Box>
            <Typography variant="subtitle2">Ordinal No.: {ordinalNumber}</Typography>
            <Typography variant="body2" sx={{ color: 'var(--fill-text-color)' }}>
              Shipment: {shipment.shipment_number || 'None'}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="caption" sx={{ color: 'var(--fill-text-color)' }} textAlign="right">
            Hedge status
          </Typography>

          <HedgingStatusIndicatorMobile hedge={shipment.company_hedge} />
        </Box>
      </Box>

      <Box className={classes.shipmentDetails}>
        <Box className={classes.detailRow}>
          <Typography variant="body2">
            {shipment.final_weight ? 'Final Weight: ' : 'Estimated Weight: '}
          </Typography>
          <Typography variant="body2">
            {shipment.final_weight
              ? `${shipment.final_weight} mt`
              : `${shipment.estimated_weight} mt`}
          </Typography>
        </Box>
        <Box className={classes.detailRow}>
          <Typography variant="body2">ETA:</Typography>
          <Typography variant="body2">{renderDateOrNone(shipment.eta)}</Typography>
        </Box>
        <Box className={classes.detailRow}>
          <Typography variant="body2">Fixed LME, USD/mt:</Typography>
          <Typography variant="body2">{shipment?.fixed_exchange || '-'}</Typography>
        </Box>
        <Box className={classes.detailRow}>
          <Typography variant="body2">Final Unit Price, USD/mt:</Typography>
          <Typography variant="body2">{shipment?.final_product_price || '-'}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
