import { Drawer, Box, IconButton, Avatar, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';
import { SidebarNavigationItem } from '../sidebar/components/navigation/components/navigationItem';
import { ACCOUNT_NAVIGATION } from '../sidebar/components/navigation';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import classes from './styles.module.scss';
import { useNavigate } from 'react-router';
import { actions as auth } from 'src/auth/reducer/auth/slice';
import { actions as company } from 'src/account/reducer/company/slice';
import { AuthRoutes } from '@app/types/navigation';

interface MobileMenuProps {
  open: boolean;
  onClose: () => void;
}

export const MobileMenu = ({ open, onClose }: MobileMenuProps) => {
  const user = useSelector((state: RootState) => state.auth.auth);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(auth.reset());
    dispatch(company.reset());

    localStorage.setItem('jwt', '');
    navigate(AuthRoutes.LOGIN);
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: '100%', backgroundColor: 'var(--other-900)' },
      }}
    >
      <Box className={classes.menuHeader}>
        <Typography variant="h6">ScrapEX</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </Box>

      <Box className={classes.menuContent}>
        {ACCOUNT_NAVIGATION.map((item) => (
          <SidebarNavigationItem
            key={item.title}
            link={item.link}
            icon={item.icon}
            title={item.title}
            onClose={onClose}
          />
        ))}
      </Box>

      <Box className={classes.userInfo}>
        <Avatar sx={{ width: 32, height: 32 }}>{user?.company_name?.[0]}</Avatar>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1">{user?.company_name}</Typography>
          <Typography variant="caption" sx={{ opacity: 0.7 }}>
            {user?.email}
          </Typography>
        </Box>
        <IconButton onClick={handleLogout} sx={{ color: 'white' }}>
          <LogoutIcon />
        </IconButton>
      </Box>
    </Drawer>
  );
};
