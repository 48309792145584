import { Typography, Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import { MobileLayout } from '@common/components/layout/mobile';
import { IShipmentUser, HedgingStatus } from '@app/types';
import { ACMContent } from './components/acmContent';
import { OrdersContent } from './components/ordersContent';
import { ShipmentsList } from './components/shipmentsList';
import { ConfirmationDialog } from './components/dialogs/confirmationDialog';
import { OrderTypeDialog } from './components/dialogs/orderTypeDialog';
import { ExchangeLevelDialog } from './components/dialogs/exchangeLevelDialog';
import { ActionButtons } from './components/actionButtons';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import {
  createHedgeThunk,
  setAnswerToQuotationThunk,
  rejectOrderThunk,
} from 'src/account/reducer/hedging/thunk';
import { ICreateHedge } from '@app/types/api';
import { Loader } from '@common/components';
import { useGetCurrentHedge } from 'src/account/contract/components/shipments/hedging/hooks/useGetCurrentHedge';
import { HedgeType } from 'src/account/contract/components/shipments/hedging/components/hedgeRequestDrawer/helpers';
import { Header } from './components/header';
import { getHedgeByIdThunk } from 'src/account/reducer/hedging/thunk';
import { dropActiveHedge, getActiveHedge } from '@api/hedging';

interface HedgeRequestProps {
  selectedShipments?: IShipmentUser[];
  onCancel: () => void;
  hedgeId?: string | null;
}

export const HedgeRequest = ({ selectedShipments, onCancel, hedgeId }: HedgeRequestProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { hedge, loading, error } = useGetCurrentHedge(
    hedgeId || null,
    selectedShipments?.map((s) => s.id) || null,
  );

  const [tabValue, setTabValue] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showOrderTypeDialog, setShowOrderTypeDialog] = useState(false);
  const [showExchangeLevelDialog, setShowExchangeLevelDialog] = useState(false);
  const [orderType, setOrderType] = useState<HedgeType>(HedgeType.TakeProfit);
  const [exchangeLevel, setExchangeLevel] = useState('');
  const [orderDate, setOrderDate] = useState<string>('');

  const totalWeight = selectedShipments?.reduce(
    (sum, shipment) => sum + (shipment.estimated_weight || 0),
    0,
  );

  const canPlaceOrder =
    orderType === HedgeType.TakeProfit
      ? Boolean(exchangeLevel)
      : Boolean(orderDate && orderDate !== '0001-01-01');

  const handleRequestQuote = () => {
    if (tabValue === 0) {
      const requestPayload: ICreateHedge = {
        shipments_id: selectedShipments!.map((s) => s.id),
        hedge_type: 'acm',
      };
      dispatch(createHedgeThunk(requestPayload));
    } else {
      const requestPayload: ICreateHedge = {
        shipments_id: selectedShipments!.map((s) => s.id),
        hedge_type: orderType,
        ...(orderType === HedgeType.TakeProfit
          ? { tp_exchange_level: parseFloat(exchangeLevel) }
          : { olme_date: orderDate }),
      };
      dispatch(createHedgeThunk(requestPayload));
    }
    setShowConfirmation(false);
  };

  const handleConfirmQuotation = () => {
    dispatch(setAnswerToQuotationThunk({ response: 'accept' }));
  };

  const handleRejectQuotation = () => {
    dispatch(setAnswerToQuotationThunk({ response: 'reject' }));
  };
  const handleRejectOrder = () => {
    dispatch(rejectOrderThunk(hedge?.id as string));
  };

  const handleOrderTypeChange = (newType: HedgeType) => {
    setOrderType(newType);
    if (newType === HedgeType.TakeProfit) {
      setOrderDate('');
    } else {
      setExchangeLevel('');
    }
    setShowOrderTypeDialog(false);
  };

  const getContentComponent = () => {
    if (hedge && hedge.hedge_status !== HedgingStatus.None) {
      switch (hedge.hedge_type) {
        case HedgeType.ACM:
          return (
            <ACMContent totalWeight={totalWeight} shipmentsCount={hedge.hedge_shipments.length} />
          );
        case HedgeType.TakeProfit:
        case HedgeType.OLME:
          return (
            <OrdersContent
              orderType={hedge.hedge_type as HedgeType}
              exchangeLevel={hedge.tp_exchange_level?.toString() || ''}
              orderDate={hedge.olme_date || ''}
              shipmentsCount={hedge.hedge_shipments.length}
              onOrderTypeClick={() => setShowOrderTypeDialog(true)}
              onExchangeLevelClick={() => setShowExchangeLevelDialog(true)}
              onDateClick={setOrderDate}
            />
          );
        default:
          return (
            <ACMContent totalWeight={totalWeight} shipmentsCount={hedge.hedge_shipments.length} />
          );
      }
    }

    return tabValue === 0 ? (
      <ACMContent totalWeight={totalWeight} shipmentsCount={hedge?.hedge_shipments.length || 0} />
    ) : (
      <OrdersContent
        orderType={orderType}
        exchangeLevel={exchangeLevel}
        orderDate={orderDate}
        shipmentsCount={hedge?.hedge_shipments.length || 0}
        onOrderTypeClick={() => setShowOrderTypeDialog(true)}
        onExchangeLevelClick={() => setShowExchangeLevelDialog(true)}
        onDateClick={setOrderDate}
      />
    );
  };

  const handleTimerExpire = async () => {
    if (hedge?.id) {
      try {
        const activeHedge = await getActiveHedge();
        if (activeHedge.status === 200) {
          try {
            await dropActiveHedge();
          } catch (e) {
            console.log('Error dropping active hedge: ', e);
          }
        }
      } catch (e) {
        console.log('Error fetching active hedge:', e);
      } finally {
        await dispatch(getHedgeByIdThunk(hedge.id));
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      {error && <p>Error: {error.message}</p>}
      {!loading && !error && hedge && (
        <MobileLayout hideHeader>
          <Header
            hedgeNumber={hedge.hedge_number}
            status={hedge.hedge_status}
            onTimerExpire={handleTimerExpire}
            deadline={
              hedge.hedge_status === HedgingStatus.Waiting
                ? hedge.quotation_deadline
                : hedge.deadline
            }
          />

          {hedge.hedge_status === HedgingStatus.None ? (
            <>
              <Tabs value={tabValue} onChange={(_, v) => setTabValue(v)} variant="fullWidth">
                <Tab label="ACM (3-month)" sx={{ textTransform: 'none' }} />
                <Tab label="Orders (3-month)" sx={{ textTransform: 'none' }} />
              </Tabs>
            </>
          ) : (
            <Typography variant="subtitle1" sx={{ p: 2 }}>
              {hedge.hedge_type === HedgeType.ACM
                ? 'At the current market (ACM)'
                : hedge.hedge_type === HedgeType.TakeProfit
                ? 'Take Profit (TP)'
                : hedge.hedge_type === HedgeType.OLME
                ? 'Official LME (OLME)'
                : 'At the current market (ACM)'}
            </Typography>
          )}

          {getContentComponent()}

          <ShipmentsList shipments={hedge.hedge_shipments} />

          <ActionButtons
            isACMTab={tabValue === 0}
            hedge={hedge}
            onCancel={onCancel}
            onRequestQuote={() => {
              if (tabValue === 1 && !canPlaceOrder) return;
              setShowConfirmation(true);
            }}
            onRejectQuotation={handleRejectQuotation}
            onRejectOrder={handleRejectOrder}
            onConfirm={handleConfirmQuotation}
            disabled={tabValue === 1 && !canPlaceOrder}
          />

          <ConfirmationDialog
            open={showConfirmation}
            hedgeNo={hedge.hedge_number}
            isACMTab={tabValue === 0}
            orderType={orderType === HedgeType.TakeProfit ? 'Take Profit' : 'OLME'}
            onCancel={() => setShowConfirmation(false)}
            onConfirm={handleRequestQuote}
          />
          <OrderTypeDialog
            open={showOrderTypeDialog}
            selectedType={orderType}
            onClose={() => setShowOrderTypeDialog(false)}
            onSelect={handleOrderTypeChange}
          />
          <ExchangeLevelDialog
            open={showExchangeLevelDialog}
            onClose={() => setShowExchangeLevelDialog(false)}
            onSubmit={setExchangeLevel}
          />
        </MobileLayout>
      )}
    </>
  );
};
