import { Box, Typography, Button, Dialog } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import classes from './styles.module.scss';
import { ScrapexGrayTextRegular } from '@common/components';

interface ConfirmationDialogProps {
  open: boolean;
  hedgeNo: string;
  isACMTab: boolean;
  orderType: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmationDialog = ({
  open,
  hedgeNo,
  isACMTab,
  orderType,
  onCancel,
  onConfirm,
}: ConfirmationDialogProps) => {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      PaperProps={{ sx: { borderRadius: '24px' } }}
    >
      <Box className={classes.container}>
        <Box className={classes.iconWrapper}>
          <InfoOutlined color="primary" sx={{ fontSize: 50 }} className={classes.icon} />
        </Box>

        <Box className={classes.content}>
          <Typography variant="h6">Hedge Request</Typography>
          <ScrapexGrayTextRegular>
            Hedge No. <span>{hedgeNo}</span>
          </ScrapexGrayTextRegular>

          <ScrapexGrayTextRegular>
            {isACMTab ? 'At the current market (ACM)' : `Orders (3-month) ${orderType}`}
          </ScrapexGrayTextRegular>

          <Typography className={classes.message}>
            {isACMTab
              ? 'By clicking the "Request a Quote" button, you will not be able to exit the hedging menu. We will send you a price indication within 5 minutes. You need to accept or decline the price indication to finalize the price fixation.'
              : 'After clicking on the "Place order" button, we will submit your order to the Exchange and notify you'}
          </Typography>
        </Box>

        <Box className={classes.actions}>
          <Button variant="contained" color="primary" fullWidth onClick={onConfirm}>
            {isACMTab ? 'REQUEST A QUOTE' : 'PLACE ORDER'}
          </Button>
          <Button variant="outlined" color="error" fullWidth onClick={onCancel} sx={{ mt: 1 }}>
            CANCEL
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
