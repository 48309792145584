import { Alert, Avatar, Badge, Box, Button, IconButton, Typography } from '@mui/material';
import { ScrapexBodyText, ScrapexBodyTextMedium, ScrapexGrayText } from '@common/components';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import { toggleReadThunk } from 'src/account/reducer/notifications/thunk';
import { INotification } from '@app/types/api';
import { formatDate, formatTimeWithoutGMT } from '@app/utils';
import { ScrapexColorEnum } from '@common/colors';

interface INotificationItemProps {
  notification: INotification;
  isHistory?: boolean;
}

export const NotificationItem = ({ notification, isHistory = false }: INotificationItemProps) => {
  const { header, message, type, title, sub_title, created_at } = notification;
  const dispatch = useDispatch<AppDispatch>();

  const handleRead = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(toggleReadThunk(notification.id));
  };

  const firstPeriodIndex = sub_title?.indexOf('. ') ?? -1;
  const contractPart =
    firstPeriodIndex >= 0 ? sub_title?.substring(0, firstPeriodIndex) : sub_title;
  const shipmentsPart = firstPeriodIndex >= 0 ? sub_title?.substring(firstPeriodIndex + 2) : '';

  return (
    <Alert
      icon={
        <Box display="flex" alignItems="center" alignSelf="flex-start">
          <Avatar sx={{ backgroundColor: '#F5F5F5', color: '#0C2452' }}>
            {type.substring(0, 1).toUpperCase() || ''}
          </Avatar>
          {!isHistory && (
            <Badge
              variant="dot"
              overlap="circular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                '& .MuiBadge-badge': {
                  borderRadius: '100%',
                  height: 10,
                  width: 10,
                  backgroundColor: '#FF0000',
                  mt: '-17px',
                  mr: '5px',
                },
              }}
            />
          )}
        </Box>
      }
      sx={{
        boxShadow: 'none',
        borderRadius: 'unset',
        borderBottom: '1px solid #E0E0E0',
        backgroundColor: 'transparent',
        p: '16px',
        '.MuiAlert-message': {
          width: '100%',
        },
      }}
    >
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <ScrapexBodyTextMedium>{title}</ScrapexBodyTextMedium>
          <ScrapexGrayText>
            {formatDate(created_at)} {formatTimeWithoutGMT(created_at)}{' '}
          </ScrapexGrayText>
        </Box>
        <Box display="flex" flexDirection="column">
          <ScrapexGrayText>{contractPart?.trim()}</ScrapexGrayText>
          <ScrapexGrayText>{shipmentsPart?.trim()}</ScrapexGrayText>
        </Box>
        <Box mt={1}>
          <ScrapexBodyText>{header}</ScrapexBodyText>
          <br />
          <span
            style={{
              color: ScrapexColorEnum.MAIN_TEXT_COLOR,
              whiteSpace: 'pre-line', // for newline from backend
              width: '350px',
            }}
          >
            {message}
          </span>
        </Box>

        {!isHistory && (
          <Button
            variant="text"
            onClick={handleRead}
            sx={{ textTransform: 'none', justifyContent: 'flex-start', p: 0, mt: 1 }}
          >
            <ScrapexGrayText>Mark as read</ScrapexGrayText>
          </Button>
        )}
      </Box>
    </Alert>
  );
};
