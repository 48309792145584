import { useMediaQuery } from '@mui/material';

interface ResponsiveWrapperProps {
  DesktopComponent: JSX.Element;
  MobileComponent: JSX.Element;
}

export const ResponsiveWrapper = ({
  DesktopComponent,
  MobileComponent,
}: ResponsiveWrapperProps) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  return isMobile ? MobileComponent : DesktopComponent;
};
