import { Box, Typography, Paper, Chip } from '@mui/material';
import { IContractUser } from '@app/types/contracts';
import classes from './styles.module.scss';
import { ContractRole, ContractStatusIndicator } from '@common/components';
import { formatDate } from '@app/utils';

interface ContractCardProps {
  contract: IContractUser;
  onClick?: (contract: IContractUser) => void;
}

export const ContractCard = ({ contract, onClick }: ContractCardProps) => {
  const {
    contract_number,
    contract_date,
    shipments_in_orders,
    request_shipments,
    hedged_shipments,
    total_shipments,
    product,
    formula,
    deal_status,
    customer_role,
  } = contract;

  const handleClick = () => {
    if (onClick) {
      onClick(contract);
    }
  };

  const unhedgedShipments =
    total_shipments -
    (hedged_shipments || 0) -
    (shipments_in_orders || 0) -
    (request_shipments || 0);

  return (
    <Box className={classes.card} onClick={handleClick}>
      <Box className={classes.header}>
        <Box>
          <Typography variant="subtitle1">{contract_number}</Typography>
          <Typography variant="caption" color="textSecondary">
            {formatDate(contract_date)}
          </Typography>
        </Box>
        <Box display="flex" gap={1}>
          <ContractRole role={customer_role} />
          <ContractStatusIndicator status={deal_status} />
        </Box>
      </Box>

      <Box className={classes.stats}>
        <Box className={classes.stat}>
          <Typography variant="h6">{shipments_in_orders || 0}</Typography>
          <Typography variant="caption">Orders</Typography>
        </Box>
        <Box className={classes.stat}>
          <Typography variant="h6">{request_shipments || 0}</Typography>
          <Typography variant="caption">Requests</Typography>
        </Box>
        <Box className={classes.stat}>
          <Typography variant="h6">{unhedgedShipments}</Typography>
          <Typography variant="caption">Unhedged</Typography>
        </Box>
      </Box>

      <Box className={classes.details}>
        <Box className={classes.detailRow}>
          <Typography variant="body2">Total Shipments:</Typography>
          <Typography variant="body2" fontWeight={500}>
            {total_shipments}
          </Typography>
        </Box>
        <Box className={classes.detailRow}>
          <Typography variant="body2">Product:</Typography>
          <Typography variant="body2" fontWeight={500}>
            {product}
          </Typography>
        </Box>
        <Box className={classes.detailRow}>
          <Typography variant="body2">Price:</Typography>
          <Typography variant="body2" fontWeight={500}>
            {formula}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
