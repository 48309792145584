import { useEffect, useState, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';
import { MobileLayout } from '@common/components/layout/mobile';
import { IHedge } from '@app/types';
import { getHedgeHistoryThunk } from 'src/account/reducer/hedging/thunk';
import { HedgeCard } from './components/hedgeCard';
import { HedgeRequest } from 'src/account/contract/contractMobile/components/hedgeRequest';
import { Loader } from '@common/components';

export const HedgeHistoryMobile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const hedges = useSelector((state: RootState) => state.account.hedging.hedgeHistory);
  const isLoading = useSelector((state: RootState) => state.account.hedging.isLoading);
  const [selectedHedge, setSelectedHedge] = useState<IHedge | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    dispatch(getHedgeHistoryThunk());
  }, [dispatch]);

  const filteredHedges = useMemo(() => {
    if (!hedges) return [];

    let result = [...hedges];

    if (searchQuery.trim()) {
      const query = searchQuery.toLowerCase().trim();
      result = result.filter((hedge) => hedge.hedge_number?.toLowerCase().includes(query));
    }

    return result.sort((a, b) => {
      const dateA = new Date(a.filled_time || 0).getTime();
      const dateB = new Date(b.filled_time || 0).getTime();
      return dateB - dateA;
    });
  }, [hedges, searchQuery]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleHedgeClick = (hedge: IHedge) => {
    setSelectedHedge(hedge);
  };

  const handleClose = () => {
    setSelectedHedge(null);
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader />;
    }

    if (!filteredHedges?.length) {
      return (
        <Typography sx={{ p: 2, textAlign: 'center', color: 'text.secondary' }}>
          {searchQuery ? 'No hedges found' : 'No hedges available'}
        </Typography>
      );
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
        {filteredHedges.map((hedge) => (
          <HedgeCard key={hedge.id} hedge={hedge} onClick={() => handleHedgeClick(hedge)} />
        ))}
      </Box>
    );
  };

  if (selectedHedge) {
    return <HedgeRequest hedgeId={selectedHedge.id} onCancel={handleClose} />;
  }

  return (
    <MobileLayout onSearch={handleSearch}>
      <Box sx={{ px: 2, pt: 2 }}>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          {`Hedge History (${filteredHedges?.length || 0})`}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          backgroundColor: 'var(--other-50)',
          minHeight: 0,
        }}
      >
        {renderContent()}
      </Box>
    </MobileLayout>
  );
};
