import { Box } from '@mui/material';
import { HedgingStatus, IHedgingDetails } from '@app/types';
import classes from './styles.module.scss';

export const hedgeStatusMap: { [key in HedgingStatus]: string } = {
  [HedgingStatus.None]: 'No Status',
  //acm
  [HedgingStatus.Quotation]: 'Quotation',
  [HedgingStatus.Waiting]: 'Request',
  [HedgingStatus.Filled]: 'Filled',
  [HedgingStatus.Confirmed]: 'Confirmed',
  [HedgingStatus.AdminRejected]: 'Adm. Canceled',
  [HedgingStatus.AdminRejectedTimeout]: 'Time Out',
  [HedgingStatus.Timeout]: 'Time Out',
  [HedgingStatus.AdminTimeout]: 'Time Out',
  [HedgingStatus.AdminTimeoutConfirmed]: 'Time Out',
  [HedgingStatus.UserTimeout]: 'Time Out',
  [HedgingStatus.UserRejectedQuotation]: 'Canceling',
  [HedgingStatus.AdminConfirmedRejectedQuotation]: 'Canceled',
  //tp
  [HedgingStatus.WaitingTP]: 'Request',
  [HedgingStatus.AtWork]: 'At work',
  [HedgingStatus.FilledTP]: 'Filled',
  [HedgingStatus.UserRejectedRequest]: 'Canceling',
  [HedgingStatus.UserRejectedOrder]: 'Canceling',
  [HedgingStatus.AdminRejectedOrderTP]: 'Adm. Canceled',
  [HedgingStatus.AdminRejectedTP]: 'Adm. Canceled',
  [HedgingStatus.AdminConfirmedRejectRequestTP]: 'Cancel Request',
  [HedgingStatus.AdminConfirmedRejectOrderTP]: 'Cancel Order',
  //olme
  [HedgingStatus.WaitingOLME]: 'Request',
  [HedgingStatus.AtWorkOLME]: 'At work',
  [HedgingStatus.FilledOLME]: 'Filled',
  [HedgingStatus.UserRejectedRequestOLME]: 'Canceling',
  [HedgingStatus.UserRejectedOrderOLME]: 'Canceling',
  [HedgingStatus.AdminRejectedOLME]: 'Adm. Canceled',
  [HedgingStatus.AdminRejectedRequestOLME]: 'Adm. Canceled',
  [HedgingStatus.AdminConfirmedRejectRequestOLME]: 'Cancel Request',
  [HedgingStatus.AdminConfirmedRejectOrderOLME]: 'Cancel Order',
};

export const HedgingStatusIndicatorMobile = ({ hedge }: { hedge?: IHedgingDetails | null }) => {
  if (!hedge) {
    return <Box className={`${classes[HedgingStatus.None]} ${classes.statusMobile}`}>No hedge</Box>;
  }

  return (
    <Box className={`${classes[hedge.hedge_status]} ${classes.statusMobile}`}>
      {hedgeStatusMap[hedge.hedge_status as HedgingStatus]} · {hedge.hedge_type.toUpperCase()}
    </Box>
  );
};

export const HedgingStatusIndicator = ({ status }: { status: HedgingStatus }) => {
  return (
    <Box className={`${classes[status]} ${classes.status}`}>
      {hedgeStatusMap[status as HedgingStatus]}
    </Box>
  );
};
